.App-header {
    .MuiToolbar-roth{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 20px 0;
        

        .logo{
            max-height: 50px;
            margin: auto 0;
        }

        .btn-back{
            color:white;
            position: absolute;
            left: 0px;
            
        }
    }
}