.link{
    color: white;
    text-decoration: none
}

@media all and (max-width: 899px){
    .footer-wrapper{
        .footer-text-wrapper, .footer-links-wrapper{
            justify-content: center;

            .footer-links, .footer-text{
                text-align: center;
            }
        }
        
    }
}